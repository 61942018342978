<template>
  <div class="find-box">
    <el-collapse v-model="activeNames" accordion>
      <el-collapse-item title="如何查找订单？" name="1">
        <el-tabs :tab-position="isMobile ? 'top' : 'right'" class="find-list">
          <el-tab-pane v-for="item in tabPayList">
            <div slot="label" class="find-icon">
              <img :src="item.icon">
              <span>{{ item.title }}</span>
            </div>
            <div class="find-cont" v-if="!isMobile">
              <div v-for="val in item.contList">
                <p>
                  <span>{{val.tip}}</span>
                  {{val.text}}
                </p>
                <img :src="val.img" alt="" />
              </div>
            </div>
            <div v-else>
              <el-carousel indicator-position="outside" height="260px">
                <el-carousel-item v-for="val in item.contList" :key="val.text">
                  <div class="find-cont2">
                    <p>
                      <span>{{val.tip}}</span>
                      {{val.text}}
                    </p>
                    <img :src="val.img" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
  export default {
    name: 'OrderExamples',
    props: ['activeNamesValue'],
    data() {
      return {
        activeNames: ['1'],
        tabPayList: [{
          title: '微信支付查询',
          icon: require('@/assets/images/icon/wx-pay.png'),
          contList: [{
            tip: '步骤1:',
            text: '通过聊天列表的微信支付，或钱包进入【账单】明细列表',
            img: require('@/assets/images/weixin_pay1.jpg')
          },{
            tip: '步骤2:',
            text: '点击支付记录,进入账单详情',
            img: require('@/assets/images/weixin_pay2.jpg')
          },{
            tip: '步骤3:',
            text: '账单详情页,找到订单号,手机复制订单号',
            img: require('@/assets/images/weixin_pay3.jpg')
          }]
        },{
          title: '支付宝支付查询',
          icon: require('@/assets/images/icon/zfb-pay.png'),
          contList: [{
            tip: '步骤1:',
            text: '打开支付宝，点击【我的】，找到【账单】，点击账单',
            img: require('@/assets/images/zfb_pay1.jpg')
          },{
            tip: '步骤2:',
            text: '点击支付记录,进入账单详情',
            img: require('@/assets/images/zfb_pay2.jpg')
          },{
            tip: '步骤3:',
            text: '账单详情页,找到订单号,手机复制订单号',
            img: require('@/assets/images/zfb_pay3.jpg')
          }]
        },{
          title: '智慧豆支付查询',
          icon: require('@/assets/images/icon/balance.png'),
          contList: [{
            tip: '步骤1:',
            text: '登录后通过会员中心或点击头像进入【我的钱包】',
            img: require('@/assets/images/balance_pay1.jpg')
          },{
            tip: '步骤2:',
            text: '在消费订单中,复制订单号进行查询',
            img: require('@/assets/images/balance_pay2.jpg')
          }]
        }],
        windowWidth: document.body.clientWidth,
        isMobile: false
      }
    },
    watch: {
      // 监听value的变化，并更新activeNames
      activeNamesValue(newVal) {
        // console.log('value=',newVal)
        this.activeNames = [newVal]; // 将newVal作为数组的第一个元素
      },
      // 监听页面宽度
      windowWidth(newWidth) {
        this.isMobile = newWidth < 901;
      }
    },
    created() {
      this.isMobile = this.windowWidth < 901;
    },
    mounted() {
      if(this.activeNamesValue){
        // 组件挂载后立即根据传入的value更新activeNames
        this.activeNames = this.activeNamesValue
      }
      window.onresize = () => {
        return (() => {
          this.windowWidth = document.documentElement.clientWidth
        })()
      }
    }
  }
</script>
<style lang="less" scoped>
  .find-box {
    position: relative;
    margin-top: 20px;
    /deep/ .el-collapse{
      border: 0;
      .el-collapse-item__header{
        font-size: 18px;
        color: #409EFF;
        font-weight: bold;
        background-color: transparent !important;
      }
    }
    .find-list{
      background: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 8px 1px;
      border-radius: 8px;
      border: 1px solid #e4e5e7;
      padding: 30px 20px;
      .find-icon{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        img{
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
        span{
          font-size: 14px;
          font-weight: bold;
        }
      }
      .find-cont{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        >div{
          width: 32%;
          max-width: 238px;
          img{
            width: 100%;
            height: auto;
          }
          p{
            min-height: 60px;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            margin-top: 10px;
            span{
              color: #e31c1c;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .find-box {
      /deep/ .el-collapse-item__header{
        background-color: transparent !important;
      }
      .find-list{
        background: transparent !important;
        box-shadow: none;
        border-radius: 0;
        border: 0;
        padding: 0;
        /deep/ .el-tabs__item{
          padding: 0 10px !important;
        }
        .find-icon{
          img{
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
          span{
            font-size: 12px;
            font-weight: initial;
          }
        }
        .find-cont2{
          width: 90%;
          height: auto;
          margin: 0 auto;
          text-align: center;
          img{
            width: auto;
            height: 190px;
            margin: 0 auto;
          }
          p{
            width: 100%;
            min-height: 50px;
            font-size: 12px;
            font-weight: bold;
            line-height: 20px;
            margin-top: 10px;
            span{
              color: #e31c1c;
            }
          }
        }
      }
    }
  }
</style>
